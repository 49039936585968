import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
} from '@bscs-dev-team/bscs-design-system-core'

import {
  Frontmatter,
  TemplateProps
} from './template-types'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import TemplateContentRow from '../components/template-content-row'


const NewsTemplate: FC<TemplateProps> = ({ data }: TemplateProps) => {
  const html: unknown = data.mdx.body
  const resource: Frontmatter = data.mdx.frontmatter

  return (
    <React.Fragment>
      <SEO
        title={resource.title}
        canonicalUrl={resource.canonicalUrl}
        description={resource.seoDescription}
        lang={resource.seoLang}
      />
      <Layout>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Our Work
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/our-work/news/'>News</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            {resource.title}
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>{resource.title}</PageTitle>
        <div className="text-md font-serif tracking-wider text-bscs-gray-400">{resource.date}</div>
        <TemplateContentRow resource={ resource } html={ html } />
      </Layout>
    </React.Fragment>
  )
}

export default NewsTemplate

export const query = graphql`
  query($nodeId: String!) {
    mdx(id: {eq: $nodeId}) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        additionalTags
        canonicalUrl
        alt
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        seoDescription
        seoLang
        sidebarCards {
          cardText
          cardTitle
          contacts {
            infoat
            person
          }
          form {
            type
            sendTo
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
          urls {
            buttonText
            external
            url
            disabled
          }
        }
        title
      }
    }
  }
`
